@import url('https://fonts.googleapis.com/css2?family=Merriweather:wght@300&display=swap');

* {box-sizing:border-box;
  margin:0;
  padding:0;
  
}
html, body {
  min-height: 100%;
  margin: 0;
  overflow-x: hidden;
  font-family: 'Merriweather', cursive;
}

/* /* HEADER STYLING */
/* NAVBAR */
/* menu page */

/* .burger div {
width: 20px;
height: 3px;
background-color:#000000;
margin-bottom:4px;
border-radius: 5px;
}  */
.navButton{
  display: none;
  position: absolute;
    right: 10px;
    top: 7px;
    padding: 5px;
}
@media (max-width: 640px) {
  #navLink{
    flex-direction:column; 
    align-items: baseline;
    padding-top: 35px;
    padding-bottom: 20px;
    height: 100%;
  }
  .navButton{
    align-self:flex-end;
    display: block;
  }
  nav{
    flex-direction: column;
    /* background-color: beige; */
  }
}
/* HOME */
/*  ==================================== Contact =================================== */
/* Contact Section */
.contactme-section {
   background-color:rgb(255, 255, 255);
    width:100%;
    
}

 .contactme-title {
    /* background-color: #f6e5ed; */
    /* border: 1px solid black; */
    text-align: center;
    /* box-shadow: 1px 3px 0px; */
    /* padding-top: 15px; */
    /* margin: 30px; */
    font-size: 40px;
    /* font-family: 'Questrial', sans-serif; */
   
}
.contactme-container{
    display:flex;
    flex: 1;
    /* border-radius:20px; */
    gap: 3rem;
    justify-content: center;
    align-content: center;
    margin-bottom: 8rem;
    margin-top: 2rem;
}

/*  Style Email Form */
.contactform{
    display:flex;
    align-items:center;
    justify-content: center;
    /* background-color:#DEB3B3; */
    /* background-color: rgb(254 243 199); */
    width: 70%;
    border-radius:30px; 
    flex-direction: column;
}
form{
    padding: 25px 30px;
    overflow: none;
    width: 100%;  
    margin-top: 20px;
}

.placeicons{
    font-family:'Font Awesome';
}
.input-group{
    margin-bottom: 30px;
    position:relative;
}
input, textarea{
    width: 100%;
    padding:10px;
    outline:0;
    border: 1px solid black;
    color: #000000;
    background-color:transparent;
    font-size: 15px;
}
label{
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    padding: 10px;
    color: #000000;
    cursor: text;
    transition: 0.2s;
    
}
input:focus~label, input:valid~label, textarea:focus~label, textarea:valid~label{
    top: -35px;
    font-size: 14px;
}

input:focus {
    border: 1px solid black;
}
textarea:focus {
    border: 1px solid black;
}
.row{
    display:flex;
    flex-direction: column;
    /* align-items: center;
    justify-content:space-around; */
    
}
.row .input-group{
    flex: 1;
}

@media screen and (max-width: 768px){
    .contactform .input-box{
        flex-direction: column;
    }
    .row{
        flex-direction: column;
       
    }
    .button-group{
        flex-direction:column;
    }
    /* .resetbtn{
        width:100%;
    } */
    .submitbtn{
        width:100%;
    }
}

/* Reset & Submit Button */
.button-group{
margin-top: 8px;
display: flex;
gap:5px;
justify-content: flex-start;
}
/* .resetbtn{
    min-width: 100px;
    cursor: pointer;
    padding:10px 0;
    color:#fff;
    outline:none;
    background:transparent;
} */
.submitbtn{
    min-width: 200px;
    cursor: pointer;
    padding:10px 0;
    border:1px solid black;
   
    outline:none;
    background:transparent;
}
.submitbtn:active {
    background-color:#feeea7;
}
  /* =========================== SOCIAL MEDIA ICONS ======================================== */
  .socialmedia ul {
    /* list-style-type: none; */
    text-decoration: none;
   color: #000000;
   border-radius: 50%;
   display:flex;
    justify-content:flex-end;
  
 }
 .socialmedia ul li {
     list-style-type: none;
     margin:0 10px;
 }
 .social-icon {
     margin-top: 20%;
 }
 .socialmedia ul li .social-icon{
   color: #000000;
   font-size: 25px;
   line-height: 50px;
   transition: .5s;
 }
 .socialmedia ul li .fa:hover {
     color:#ffffff;
 }
 .socialmedia ul li a {
     position:relative;
     display:block;
     /* Where I can size the icon background */
     width:50px;
     height:50px;
     border-radius:50%;
     background-color: white;
     text-align: center;
     transform: .6s;
     box-shadow: 0 5px 4px rgb(160, 128, 128);
   /* Possible box shadow color */
     /* background-color: #F6E1E1; */
 }

 /* Hovering w. Social Media */
 .socialmedia ul li a:hover {
   transform: rotate(0deg) skew(0deg) translate(0, -10px);
   transition: all .4s ease;
   -webkit-transition: all .4s ease;
 }
 
/* Github Color */
.socialmedia ul li:nth-child(1) a:hover {
   background-color:#6e5494;
   color: #fff;
}
 /* Linkedin color */
 .socialmedia ul li:nth-child(3) a:hover {
   background-color: #0077B5;
   color: #fff;
}   /*   Email Color */
.socialmedia ul li:nth-child(2) a:hover {
   background-color: #DEDBD2;
   color: #fff;
}
 .socialmedia a:link {
    text-decoration: none;
     color: #000000;
 }
 .socialmedia a:visited {
     color: #000000;
 }
 /* .socialmedia a:hover {
     color: #951515;
 } */
 .socialmedia a:active {
     color: #000000;
 }

/* FOOTER */
.Footer {
  position: static;
  background-color: rgb(254 243 199);
  min-height: 208px;
  width: 100%;
  bottom: 0;
}